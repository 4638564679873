
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
































































.card {
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  width: 62px;
  height: 88px;
  vertical-align: middle;
  user-select: none;

  &.selected {
    background-color: #ccc;
  }
  .value {
    font-size: 24px;
    font-weight: bold;
  }
}
.suit {
  width: 50%;

  svg {
    width: 100%;
    height: auto;
  }
}
